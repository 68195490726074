import * as React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <div className="flex h-screen w-screen">
      <h1 className="font-Oswald m-auto text-4xl">404: Page Not Found</h1>
    </div>
  </Layout>
);

export default NotFoundPage;
